bcg-people-profile {
    --bs-color: var(--bs-secondary);
    --bs-color-hover: #5bb9a2;
}

.navbar-brand {
    margin-left: 1rem;
}

.navbar-toggler {
    border: none;
}

nav.navbar {
    position: -webkit-sticky;
    /* Safari */
    position: sticky;
    top: 0;
    border-bottom: 1px solid #d3d3d3;
    z-index: 9999;
}

.rightLinks {
    color: #696969;
    padding-right: 2rem !important;
    text-decoration: none;
}

.hamburgerMenu {
    margin-right: 1rem !important;
}

@media (max-width: 801px) {
    .navbar-toggler {
        padding-left: 0;
    }

    .navbar-brand {
        font-size: 1rem !important;
    }

    .navBarContainer {
        display: none;
    }

    .hamburgerLinks {
        color: #696969;
        padding-left: 2rem !important;
        text-decoration: none;
    }
}

@media (min-width: 801px) {
    .navbar-expand-custom {
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
    }

    .navbar-expand-custom .navbar-nav {
        flex-direction: row;
    }

    .navbar-expand-custom .dropdown-menu {
        position: absolute;
    }

    .navbar-expand-custom .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
    }

    .navbar-expand-custom > .container {
        flex-wrap: nowrap;
    }

    .navbar-expand-custom .navbar-collapse {
        display: flex !important;
        flex-basis: auto;
    }

    .navbar-brand {
        font-size: 1rem !important;
    }

    .navbar-expand-custom .navbar-toggler {
        display: none;
    }

    .hamburgerLinks {
        color: #696969;
        padding-left: 2rem !important;
        text-decoration: none;
    }
}
