.main-panel {
    height: 100% !important;
    display: flex;
    flex-flow: row;
    padding: 0 !important;
    align-items: baseline;
    align-content: space-around;
}

.leftPanel {
    width: 75%;
    height: 100%;
}

.rightPanel {
    min-width: 300px;
    width: 25%;
}

@media (max-width: 800px) {
    .main-panel {
        display: grid;
        padding-left: 1rem;
        padding-right: 1rem;
        margin-top: 0;
    }

    .leftPanel {
        width: 100%;
        padding-top: 0;
        margin-bottom: 10rem;
    }

    .rightPanel {
        width: 100%;
    }
}
