.content {
    height: 100%;
}

.mainPanel {
    display: flex;
    margin-left: 2rem;
}
.inValidDate {
    color: #d82216;
    font-size: 0.75rem;
}

@media (max-width: 800px) {
    .mainPanel {
        display: grid;
        margin-left: 1rem;
        padding-right: 1.5rem;
    }
}

.space {
    padding-right: 5px;
}
