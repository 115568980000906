.side-nav {
    padding: 1em;
}

.index-list {
    display: flex;
    flex-flow: column;
    margin-top: 1em;
}

.index {
    color: #535353;
    font-weight: 400;
    font-size: 16px;
    border-left: #cccccc 2px solid;
    padding-left: 1em;
    text-decoration: none;
    line-height: 20px;
    padding-bottom: 10px;
}

.index:active,
.index-active {
    color: #323232;
    font-weight: 700;
    border-left: #323232 3px solid;
}
