.requestPage {
    padding: 2rem;
}

.headerSection {
    margin-bottom: 1rem;
}

.headerSection p {
    font-weight: bold;
    font-size: 20px;
}

.headerSection button {
    display: none;
}

.row {
    margin-top: 0.5rem;
}

.title {
    margin-top: 1rem;
}

.button-row {
    margin-top: 4rem;
}

.captcha-row {
    margin-top: 2rem;
}

@media (max-width: 800px) {
    .headerSection {
        display: flex;
        width: 100%;
        padding: 0;
    }

    p {
        width: 100%;
    }

    .headerSection button {
        display: inline-block;
        margin-left: auto;
    }

    .pageInfo {
        display: none;
    }

    .button-row {
        display: flex;
    }

    .captcha-row {
        display: none;
    }

    .col-md {
        margin-bottom: 1rem;
    }
}
