.content {
    font-size: 20px;
    padding-bottom: 20px;
}

.box {
    padding: 15px;
    margin-top: 10px;
    border: 1px solid lightgrey;
    text-align: justify;
}

.description {
    font-size: 16px;
    padding-bottom: 10px;
}

.helpButton {
    text-align: right;
}

.mb-3 {
    text-align: justify;
}
