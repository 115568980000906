.content {
    font-size: 20px;
    padding-bottom: 20px;
}

.box {
    border: 1px solid lightgrey;
    text-align: justify;
    background-color: white;
}

.description {
    font-size: 16px;
    padding-bottom: 10px;
}

.helpButton {
    text-align: right;
}

.mb-3 {
    text-align: justify;
}
