button {
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
}

.on {
    color: green;
}

.off {
    color: #ccc;
}

.modal-backdrop {
    opacity: 0.5 !important;
}

@media (max-width: 800px) {
    .modal {
        top: 5rem;
    }
}
