@import '@bcg-web/bcg-bootstrap/dist/bcg-bootstrap.min.css';

html,
body,
#root {
    height: 100vh;
    background-color: white;
}

.spinner-border-lg {
    position: relative;
    top: 50%;
    left: 50%;
    width: 3rem;
    height: 3rem;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    font-family: 'Henderson BCG Sans', sans-serif;
    color: #323232;
}

h3 {
    font-size: 24px;
    font-weight: 700;
}

h4 {
    font-size: 20px;
    font-weight: 700;
}

p {
    font-size: 20px;
    font-weight: 400;
}
