.pageHeading {
    padding-top: 20px;
    margin-bottom: 1em;
}

.pageTop {
    margin-bottom: 1em;
}

h3 {
    font-size: 24px;
    font-family: 'Henderson BCG Sans', sans-serif;
    color: #323232;
    font-weight: 700;
}

h4 {
    font-size: 20px;
    font-family: 'Henderson BCG Sans', sans-serif;
    color: #323232;
    font-weight: 700;
}

p {
    font-size: 16px;
    font-family: 'Henderson BCG Sans', sans-serif;
    color: #323232;
    font-weight: 400;
}

p > a {
    color: #323232;
}
